import React from 'react';
import { Metadata } from '@latitude/metadata';
import Layout from '@/components/layout';
import Lframe from '@/components/Lframe/Lframe';
import { SITE_URL, TITLE_SUFFIX } from '@/utils/constants';
import Hero from './_hero';
import DigitalWalletsLinks from './_links';

const DigitalWalletsPage = ({ location }) => (
  <Layout location={location}>
    <main className="navigation-spacer">
      <Metadata
        title={`Add your credit card to your digital wallet | ${TITLE_SUFFIX}`}
        description="Digital wallets are now available to Latitude customers.."
        canonical={`${SITE_URL}${location.pathname}`}
      />
      <Lframe payAnyWay />
      <Hero />
      <DigitalWalletsLinks location={location} />
    </main>
  </Layout>
);

export default DigitalWalletsPage;
